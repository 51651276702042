import React, { Component } from "react";
import I18n from "../I18n";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {QRCodeCanvas} from 'qrcode.react';
import {
  getTime,
  webAuth,
  socialLogin,
  loginApple,
  loginEmail,
  checkPasscode,
  ssoCanny,
} from "../api/login.api";
import { LOGIN_REDIRECT_URL, FACEBOOK_LOGIN_APP_ID } from "../config/ApiConfig";
import Loader from "../components/Loader";
import {
  isMobile,
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import { Helmet } from "react-helmet";
import { getToken } from "firebase/app-check";
import { toast } from "react-toastify";
import { getTrust, getUUID } from "../selectors";

function GoogleSignIn(props) {
  const login = useGoogleLogin({
    onSuccess: props.onSuccess,
  });

  return (
    <button
      type="button"
      className="btn btn-outline-light mb-1 btn-block text-center rounded-pill"
      onClick={login}
    >
      <img
        src={require("../assets/images/google_signin.png")}
        style={{ width: 18, height: 18 }}
        alt="Google Sign in"
        className="mr-3"
      />
      Sign in with Google
    </button>
  );
}

function cookieTest() {
  try {
    // Create cookie
    document.cookie = "cookietest=1";
    var ret = document.cookie.indexOf("cookietest=") !== -1;
    // Delete cookie
    document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
    return ret;
  } catch (e) {
    return false;
  }
}

function localStorageTest() {
  const mod = "slowly-test";
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
}

function getQueryParameterByName(name) {
  var pairStrings = window.location.search.slice(1).split("&");
  var pairs = pairStrings.map(function (pair) {
    return pair.split("=");
  });
  return pairs.reduce(function (value, pair) {
    if (value) return value;
    return pair[0] === name ? decodeURIComponent(pair[1]) : null;
  }, null);
}

function getRedirectURL(ssoToken) {
  var redirectURL = getQueryParameterByName("redirect");
  var companyID = getQueryParameterByName("companyID");

  if (!redirectURL || redirectURL.indexOf("https://") !== 0 || !companyID) {
    global.log("turn off sso mode");
    return null;
  }
  return (
    "https://canny.io/api/redirects/sso?companyID=" +
    companyID +
    "&ssoToken=" +
    ssoToken +
    "&redirect=" +
    redirectURL
  );
}

class Login extends Component {
  constructor(props, context) {
    super(props, context);

    const localStorage = localStorageTest();
    const cookie = cookieTest();

    const trusted =
      this.props.trusted && !!localStorage && !!cookie && !this.props.onSuccess;

    this.state = {
      uuid: props.uuid,
      timestamp: null,
      retry: 0,
      pauseRetry: false,
      trusted,
      localStorage,
      cookie,
      loading: true,
    };

    if (trusted !== this.props.trusted) this.props.trustDevice(trusted);
  }

  componentDidMount() {
    // if (!!isMobile) window.top.location = "https://slowly.app/download";
    // else if (!this.props.token && !!this.props.uuid) this.setTime();
    const pathname = window.top.location.pathname;
    if (pathname.indexOf("/login/canny") === 0) {
      global.log("SSO - target canny.io");
      this.setState({
        ssoMode: true,
      });

      if (!!this.props.token) {
        this.getCannySSO(this.props.token);
      } else {
        this.setState({
          loading: false,
        });
      }
    } else if (!!isMobile && !this.props.onSuccess) {
      window.top.location = "https://slowly.app/download";
    } else {
      this.setState({
        loading: false,
        ssoMode: false,
      });
    }
  }

  getCannySSO = async (token) => {
    global.log("getCannySSO");
    try {
      const { ssoToken } = await ssoCanny({ token });
      global.log("exchange token success", ssoToken);
      const redirectURL = getRedirectURL(ssoToken);
      if (redirectURL) {
        window.location.assign(redirectURL);
      } else {
        global.log("SSO - Unable to redirect");
        this.setState({
          ssoMode: false,
          loading: false,
        });
        this.props.gotToken({ token });
      }
    } catch (error) {
      global.log("sso error", error);
      this.props.logout();
      this.setState({
        loading: false,
      });
      if (error.error) {
        toast.error(I18n.t(error.error), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeOnClick: true,
        });
      } else {
        toast.error(I18n.t("INTERNAL_ERROR"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeOnClick: true,
        });
      }
    }
  };

  initEmail = () => {
    this.setState({
      useEmail: true,
      email: "",
      checkPasscode: false,
    });
  };

  handlePasscodeChange = (event) => {
    this.setState({
      passcode: event.target.value,
    });
  };

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  submitEmail = async (e) => {
    e.preventDefault();

    if (!this.state.email || this.state.email === "") {
      this.setState({ error: "EMAIL_INVALID" });
      return false;
    }

    const device = {
      uuid: this.props.uuid,
      os: osName + " " + osVersion,
      browser: browserName + " " + browserVersion,
    };

    this.setState({
      loading: false,
      checkingEmail: true,
      error: null,
    });

    try {
      const firebaseRes = await getToken(global.appCheck);
      global.log("firebaseRes", firebaseRes);
      await this.setState({ appCheckToken: firebaseRes.token });
    } catch (error) {
      global.log("firebase appcheck error", error);
    }

    try {
      if (this.state.checkPasscode) {
        const result = await checkPasscode({
          email: this.state.email,
          passcode: this.state.passcode,
          device,
          appCheckToken: this.state.appCheckToken,
        });
        if (this.state.ssoMode) {
          this.getCannySSO(result.token);
        } else {
          this.props.gotToken({
            token: result.token,
            locale: this.props.locale,
            skip: this.props.isModal,
          });
        }
        if (this.props.onSuccess) this.props.onSuccess();
      } else {
        const response = await loginEmail({
          email: this.state.email,
          device,
          checkpass: false,
          appCheckToken: this.state.appCheckToken,
        });
        global.log("loginEmail", response);
        this.setState({ checkingEmail: false, checkPasscode: true });
      }
    } catch (error) {
      global.log("loginError ", error);
      if (error.reactivate) {
        toast.error(
          I18n.t("Your account has been deactivated") +
            " (" +
            I18n.t("SLOWLY_WEB_STEP1") +
            ")",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            closeOnClick: true,
          }
        );
      } else if (error.error) this.setState({ error: error.error });
      else
        toast.error(I18n.t("INTERNAL_ERROR"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeOnClick: true,
        });

      this.setState({
        checkingEmail: false,
      });
    }
  };

  initQR = () => {
    this.setState(
      {
        useQR: true,
        timestamp: null,
        retry: 0,
        pauseRetry: false,
      },
      this.setTime
    );
  };

  setTime = async () => {
    const { now } = await getTime();
    global.log(now);
    this.setState({ timestamp: now, retry: 0, pauseRetry: false });
    setTimeout(this.checkAuthStatus, 4000);
  };

  _pauseRetry = () => {
    this.setState({ pauseRetry: true });
  };

  checkAuthStatus = async () => {
    if (!!this.props.token || !this.state.useQR || this.state.pauseRetry) {
      this._pauseRetry();
      return false;
    }

    try {
      const result = await webAuth({
        ...this.state,
        os: osName + " " + osVersion,
        browser: browserName + " " + browserVersion,
        trusted: !!this.state.trusted,
      });
      global.log("checkAuthStatus", result);

      if (!!result.token) {
        if (this.state.ssoMode) {
          this.getCannySSO(result.token);
        } else if (!!result.locale && result.locale !== "en") {
          this.props.changeUI(result.locale);
          this.props.reInit(result.token, true);
        } else {
          this.props.gotToken({
            token: result.token,
            locale: this.props.locale,
            skip: !!this.props.onSuccess,
          });
        }
        if (this.props.onSuccess) this.props.onSuccess();
      }
    } catch (e) {
      global.log(e);
      this.setState({ retry: this.state.retry + 1 });
      global.log("retry", this.state.retry);

      if (this.state.retry >= 15) this._pauseRetry();
      else setTimeout(this.checkAuthStatus, 4000);
    }
  };

  // changeLang = (ev) => {
  //   const key = ev.value;
  //   if (key === this.props.locale) return false;
  //   this.props.changeUI(key);
  //   this.props.reInit();
  //   // I18n.init(key)
  //   // this.setState({ locale: key })
  // };

  toggleTrust = () => {
    this.setState({
      trusted: !this.state.trusted,
    });
    this.props.trustDevice(!this.state.trusted);
  };

  _socialLogin = async ({ access_token, method, id }) => {
    this.setState({
      loading: true,
    });

    try {
      const result = await socialLogin({ access_token, method, id });
      global.log("socialLogin", result);

      if (!!result.token) {
        if (this.state.ssoMode) {
          this.getCannySSO(result.token);
        } else {
          this.props.gotToken({
            token: result.token,
            locale: this.props.locale,
            trusted: !!this.state.trusted,
            skip: !!this.props.onSuccess,
          });
          this.setState({ loading: false });
        }
        if (this.props.onSuccess) this.props.onSuccess();
      }
    } catch (error) {
      global.log("socialLogin error", error);
      if (error.reactivate) {
        toast.error(
          I18n.t("Your account has been deactivated") +
            " (" +
            I18n.t("SLOWLY_WEB_STEP1") +
            ")",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            closeOnClick: true,
          }
        );
      } else if (error.error) {
        toast.error(I18n.t(error.error), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeOnClick: true,
        });
      } else {
        toast.error(I18n.t("INTERNAL_ERROR"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeOnClick: true,
        });
      }
      this.setState({ loading: false });
    }
  };

  facebookOnSuccess = (result) => {
    this._socialLogin({
      access_token: result.accessToken,
      method: "fb",
      id: result.userID,
      data: result,
    });
  };

  googleOnSucecss = (result) => {
    global.log("googleOnSucecss", result);
    this._socialLogin({
      access_token: result.access_token,
      method: "google-web",
      id: result.access_token,
      data: result,
    });
  };

  appleOnSuccess = async (response) => {
    global.log("response.data", response);
    if (!response.error) {
      try {
        const result = await loginApple({
          identity_token: response.authorization.id_token,
          authorization_code: response.authorization.code,
          redirect_uri: true,
        });

        global.log("result", result);
        if (!!result.token) {
          if (this.state.ssoMode) {
            this.getCannySSO(result.token);
          } else {
            this.props.gotToken({
              token: result.token,
              locale: this.props.locale,
              trusted: !!this.state.trusted,
              skip: !!this.props.onSuccess,
            });
          }
          if (this.props.onSuccess) this.props.onSuccess();
        }
      } catch (error) {
        global.log("error", error);
        global.log("socialLogin error", error);
        if (error.reactivate) {
          toast.error(
            I18n.t("Your account has been deactivated") +
              " (" +
              I18n.t("SLOWLY_WEB_STEP1") +
              ")",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false,
              closeOnClick: true,
            }
          );
        } else if (error.error) {
          toast.error(I18n.t(error.error), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            closeOnClick: true,
          });
        } else {
          toast.error(I18n.t("INTERNAL_ERROR"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            closeOnClick: true,
          });
        }

        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { loading, isModal = false } = this.props;
    const { pauseRetry } = this.state;
    const baseClass =
      "login-wrapper w-100 d-flex flex-column justify-content-center align-items-center p-3";

    if (!!loading || this.state.loading) {
      if (!this.props.isModal) return <Loader />;
      else
        return (
          <div className={baseClass + " p-5"}>
            <div className="spinner-border text-secondary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        );
    }

    if (!!this.props.token && !this.state.ssoMode && !this.props.isModal && !this.props.onSuccess)
      return <Redirect to="/home" />;

    if (!!this.props.token && !!this.props.onSuccess)
      return (
        <div className={baseClass + " p-5"}>
          <div className="spinner-border text-secondary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );

    const _device = {
      uuid: this.props.uuid,
      os: osName + " " + osVersion,
      browser: browserName + " " + browserVersion,
      timestamp: this.state.timestamp,
    };
    const code = JSON.stringify(_device);

    return (
      <div className={isModal ? baseClass : baseClass + " full"}>
        <Helmet>
          <title>Sign in to Slowly</title>
        </Helmet>
        <GoogleOAuthProvider clientId="665290019748-fmr8tmkt60scmo7g1j161at2rriqjf0i.apps.googleusercontent.com">
          <div
            className={
              isModal
                ? "w-100 pt-5 pb-3"
                : "narrow-container card mb-2 shadow pt-5 pb-3 rounded-lg"
            }
          >
            <div className="mb-2 text-center">
              <img
                src="https://slowly.app/wp-content/themes/slowly/assets/img/logo.svg"
                alt="Slowly"
                className="logo mb-2"
              />
              <h4>{ this.state.ssoMode ? "Continue with your Slowly account" : "Sign in to Slowly" }</h4>
            </div>

            {this.state.useEmail ? (
              <form className="px-5 mb-3 mt-3" onSubmit={this.submitEmail}>
                {this.state.checkPasscode ? (
                  <div>
                    <p>{I18n.t("VALIDATE_TIPS")}</p>
                    <input
                      type="number"
                      className={"form-control"}
                      id="passcode"
                      value={this.state.passcode}
                      onChange={this.handlePasscodeChange}
                      disabled={this.state.checkingEmail}
                      placeholder={I18n.t("ONE_TIME_PASSCODE")}
                      maxlength="6"
                    />
                  </div>
                ) : (
                  <input
                    type="email"
                    className={"form-control"}
                    id="email"
                    placeholder={I18n.t("EMAIL")}
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    disabled={this.state.checkingEmail}
                  />
                )}
                {this.state.checkingEmail ? (
                  <div className="text-center m-3">
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button
                    className="btn btn-primary btn-block mt-2"
                    onClick={this.submitEmail}
                  >
                    {this.state.checkPasscode
                      ? I18n.t("VALIDATE")
                      : I18n.t("CONTINUE")}
                  </button>
                )}

                {this.state.error && !this.state.checkingEmail && (
                  <p className="mt-3 text-danger">
                    <i className="icon-alert" /> {I18n.t(this.state.error)}
                  </p>
                )}

                {!this.state.checkingEmail && (
                  <button
                    className="btn mt-5 ml-n2"
                    onClick={() => {
                      this.setState({
                        useEmail: false,
                      });
                    }}
                  >
                    <i className="icon-chevron-left mr-2" />
                    {I18n.t("BACK")}
                  </button>
                )}
              </form>
            ) : this.state.useQR ? (
              <div className="p-3 mb-3">
                <div className="position-relative qr-wrapper mt-n4">
                  { !!this.state.timestamp && (
                    <QRCodeCanvas value={code} size={260} marginSize={5}  />
                  )}

                  {!!pauseRetry && (
                    <div
                      className="qr-block d-flex justify-content-center align-items-center link"
                      onClick={this.setTime}
                    >
                      <i className="icon-refresh h3 text-primary mt-0 mb-0" />
                    </div>
                  )}
                </div>
                <div>
                  {!!this.state.localStorage &&
                    !!this.state.cookie &&
                    !isModal && (
                      <div className="form-group form-check small text-center mb-5">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="trustcheck"
                          checked={this.state.trusted}
                          onChange={this.toggleTrust}
                          disabled={
                            !this.state.localStorage || !this.state.cookie
                          }
                        />
                        <label
                          className="form-check-label text-light"
                          htmlFor="trustcheck"
                        >
                          {I18n.t("SLOWLY_WEB_PERSIST_CHECKBOX")}
                        </label>
                      </div>
                    )}
                  <ol className="small mt-3">
                    <li>{I18n.t("SLOWLY_WEB_STEP1")}</li>
                    <li>{I18n.t("SLOWLY_WEB_STEP2")}</li>
                    <li>{I18n.t("SLOWLY_WEB_STEP3")}</li>
                  </ol>

                  <button
                    className="btn btn-light ml-4 mt-3"
                    onClick={() => {
                      this.setState({
                        useQR: false,
                      });
                    }}
                  >
                    <i className="icon-chevron-left mr-2" />
                    {I18n.t("BACK")}
                  </button>
                </div>
              </div>
            ) : (
              <div className="px-5 pt-3">
                <button
                  type="button"
                  className="btn btn-outline-light mb-1 btn-block text-center rounded-pill"
                  onClick={this.initQR}
                >
                  <i className="icon-qr_code_scanner mr-2 text-muted" /> Sign in
                  with QR code
                </button>
                <GoogleSignIn onSuccess={this.googleOnSucecss} />
                <AppleLogin
                  clientId="app.slowly.web"
                  redirectURI={LOGIN_REDIRECT_URL}
                  usePopup={true}
                  callback={this.appleOnSuccess}
                  responseMode="query"
                  render={(
                    renderProps //Custom Apple Sign in Button
                  ) => (
                    <button
                      type="button"
                      className="btn btn-outline-light mb-1 btn-block text-center rounded-pill"
                      onClick={renderProps.onClick}
                    >
                      <i className="icon-apple mr-2" /> Sign in with Apple
                    </button>
                  )}
                />
                <FacebookLogin
                  appId={FACEBOOK_LOGIN_APP_ID}
                  onSuccess={this.facebookOnSuccess}
                  onFail={(error) => {
                    global.log("Login Failed!", error);
                  }}
                  render={({ onClick, logout }) => (
                    <button
                      type="button"
                      className="btn btn-outline-light mb-1 btn-block text-center rounded-pill"
                      onClick={onClick}
                    >
                      <i
                        className="icon-facebook mr-2"
                        style={{ color: "#3b5998" }}
                      />
                      Sign in with Facebook
                    </button>
                  )}
                />
                <button
                  type="button"
                  className="btn btn-outline-light mb-1 btn-block text-center rounded-pill"
                  onClick={this.initEmail}
                >
                  <i className="icon-envelope mr-2 text-muted" /> Sign in with
                  Email
                </button>

                {!!this.state.localStorage &&
                !!this.state.cookie &&
                !isModal ? (
                  <div className="form-group form-check small text-center mb-3 mt-5">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="trustcheck"
                      checked={this.state.trusted}
                      onChange={this.toggleTrust}
                      disabled={!this.state.localStorage || !this.state.cookie}
                    />
                    <label
                      className="form-check-label text-light"
                      htmlFor="trustcheck"
                    >
                      {I18n.t("SLOWLY_WEB_PERSIST_CHECKBOX")}
                    </label>
                  </div>
                ) : (
                  <div className="mb-5" />
                )}
              </div>
            )}
          </div>
          { this.state.ssoMode && (
            <div className="narrow-container mt-3 mb-3 text-light small">
            feedback.slowly.app is powered by <a href="https://canny.io/" target="_blank"><u>Canny</u></a>.<br/>

            By proceeding, you agree to share your Slowly account information with <a href="https://canny.io/" target="_blank"><u>Canny</u></a>, including your avatar image, nickname, and email address. For more details, please review <a href="https://canny.io/privacy" target="_blank"><u>Canny’s privacy policy</u></a>.
            </div>
          )}
          {!isModal && (
            <div className="narrow-container">
              <div className="mt-3">
                { !this.state.ssoMode && (
                  <p className="text-lighter mb-2">
                    Don't have an account? &nbsp;
                    <a href={"https://slowly.app/download/"}>
                      <u>{I18n.t("Join Now")}</u>
                    </a>
                  </p>
                )}
                
                <p className="text-lighter small">
                  <a
                    href={"https://slowly.app"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    <i className="icon-slowly mr-1" />
                    Slowly
                  </a>
                  &nbsp; · &nbsp;
                  <a
                    href={"https://slowly.app/terms/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    {I18n.t("TERMS")}
                  </a>
                  &nbsp; · &nbsp;
                  <a
                    href={"https://slowly.app/privacy/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    {I18n.t("PRIVACY")}
                  </a>
                  &nbsp; · &nbsp;
                  <a
                    href={"https://help.slowly.app"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    {I18n.t("FAQ")}
                  </a>
                </p>
              </div>
            </div>
          )}
        </GoogleOAuthProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uuid: getUUID(state),
    token: state.me.token,
    locale: state.slowly.locale,
    loading: state.slowly.loading,
    trusted: getTrust(state),
  };
};

const saveToken = function saveToken(token) {
  return {
    type: "REFRESH_TOKEN",
    token,
  };
};

const gotToken = function gotToken({
  token,
  locale = "en",
  trusted = false,
  skip = false,
}) {
  return {
    type: "GOT_TOKEN",
    token,
    trusted,
    locale,
    skip,
  };
};

const changeUI = function changeUI(locale) {
  return {
    type: "SAVE_UI_LOCALE_SUCCESS",
    locale,
  };
};

const reInit = function reInit(token, newLocale = false) {
  return {
    type: "INIT_APP",
    token,
    newLocale,
  };
};

const trustDevice = function trustDevice(trust) {
  return {
    type: "TRUST_DEVICE",
    trust,
  };
};

const logout = () => {
  return {
    type: "LOGOUT",
  };
};

export default connect(mapStateToProps, {
  gotToken,
  saveToken,
  changeUI,
  reInit,
  trustDevice,
  logout,
})(Login);
